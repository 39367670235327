<template>
  <div class="UserInfo">
    <div class="info">
      <el-card class="box_card">
        <el-tabs v-model="activeTab" stretch>
          <el-tab-pane label="基本信息" name="first">
            <el-form :model="form" label-width="90px" label-position="left">
              <el-form-item label="姓名:">
                <el-input
                  v-model="form.name"
                  :disabled="form.is_verfy"
                ></el-input>
              </el-form-item>
              <el-form-item label="性别:">
                <el-radio-group v-model="form.sex">
                  <el-radio label="1">男</el-radio>
                  <el-radio label="0">女</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="电子邮箱:">
                <el-input
                  v-model="form.email"
                  placeholder="请输入你的电子邮箱"
                ></el-input>
              </el-form-item>
              <el-form-item label="现有学历:">
                <el-input
                  v-model="form.education"
                  type="text"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item label="所在城市:">
                <el-input
                  v-model="form.city"
                  type="text"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item label="地址:">
                <el-input
                  v-model="form.address"
                  placeholder="请输入你的地址"
                ></el-input>
              </el-form-item>
              <el-form-item label="身份证号:">
                <el-input
                  v-model="form.idcard"
                  :disabled="form.is_verfy"
                  placeholder="请输入你的身份证号"
                  @paste.native.capture.prevent="()=>{return false}"
                ></el-input>
              </el-form-item>
              <el-form-item label="推荐人编号:">
                <el-input
                  v-model="form.agent"
                  :disabled="readonly"
                  placeholder="没有推荐人请勿填写"
                ></el-input>
              </el-form-item>
              <el-form-item class="btn">
                <el-button type="primary" @click="onSubmit">保存</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="修改密码" name="second">
            <el-form
              :model="resetpass"
              label-width="80px"
              ref="form"
              label-position="left"
            >
              <el-form-item label="新密码:">
                <el-input
                  v-model="resetpass.password"
                  type="password"
                  placeholder="请输入新密码"
                ></el-input>
              </el-form-item>
              <el-form-item label="确认密码:">
                <el-input
                  v-model="resetpass.rpassword"
                  type="password"
                  placeholder="请再次输入新密码"
                ></el-input>
              </el-form-item>
              <el-form-item class="btn">
                <el-button
                  type="primary"
                  @click="reset('form')"
                  :disabled="isDisabled"
                  >保存</el-button
                >
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="修改手机号" name="third">
            <el-form
              :model="mobileForm"
              label-width="80px"
              label-position="left"
              class="phoneform"
            >
              <el-form-item label="当前手机号：">
                <span>{{ form.mobile }}</span>
              </el-form-item>
              <el-form-item label="新手机号码:">
                <el-input
                  v-model="mobileForm.mobile"
                  placeholder="请输入新的手机号"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  class="getcode"
                  :disabled="isClick"
                  @click="getCode(mobileForm.mobile, 'update')"
                  >{{ sendCon }}</el-button
                >
              </el-form-item>
              <el-form-item label="验证码:">
                <el-input
                  v-model="mobileForm.codes"
                  type="email"
                  placeholder="请输入验证码"
                ></el-input>
              </el-form-item>
              <el-form-item class="btn">
                <el-button type="primary" @click="resetMobile">保存</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </div>
  </div>
</template>
<script>
let _this
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.resetpass.rpassword !== '') {
          this.$refs.form.validateField('rpassword')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.resetpass.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      activeTab: 'first',
      show: '',
      timer: null,
      count: '',
      sendCon: '获取验证码',
      isClick: false,
      setPhoneType: '',
      form: {},
      resetpass: {
        mobile: '',
        // codes: "",
        password: '',
        rpassword: '',
      },
      work: [],
      mobileForm: {},
      rules: {
        password: { required: true, validator: validatePass, trigger: 'blur' },
        // codes: { required: true, message: "请输入验证码", trigger: "blur" },
        rpassword: {
          required: true,
          validator: validatePass2,
          trigger: 'blur',
        },
      },
      isDisabled: false,
      loading: false,
      readonly: false,
    }
  },
  created() {
    this.init()
    this.agentstore()
  },
  methods: {
    //处理form数据
    init() {
      _this = this
      _this.form = this.$store.state.wps_user
      _this.form.sex = _this.form.sex.toString()
      _this.form.is_verfy = _this.form.is_verfy == 1 ? true : false
    },
    //完善 信息
    onSubmit() {
      _this.loading = true
      let form = JSON.parse(JSON.stringify(this.form))
      form.resume = JSON.stringify(form.resume)
      form.cert_number_img = JSON.stringify(form.cert_number_img)
      form.objective = JSON.stringify(form.objective)
      form.way = JSON.stringify(form.way)
      _this.$http
        .post(process.env.VUE_APP_URL + 'user', form)
        .then(data => {
          if (data.data.code == 200) {
            _this.$message.success('保存成功')
            _this.$store.commit('setUser', form)
            _this.init()
            _this.loading = false
          } else {
            _this.$message.error(data.data.msg)
            _this.loading = false
          }
        })
        .catch(err => {
          _this.$message.error('网络错误，请重试')
          _this.loading = false
          console.log(err)
        })
    },
    //获取验证码
    getCode(mobile, type) {
      let params = {
        type: type,
        mobile: mobile,
      }
      this.setPhoneType = type
      if (/^1[3456789]\d{9}$/.test(mobile)) {
        _this.$http
          .post(process.env.VUE_APP_URL + 'sendCode', params)
          .then(data => {
            if (data.data.code == 200) {
              this.countdown()
              _this.$message.success('验证码已发送')
            } else {
              _this.$message.error(data.data.msg)
            }
          })
          .catch(err => {
            _this.$message.error('网络错误，请重试')

            console.log(err)
          })
      } else {
        this.$message.warning('请输入正确格式的手机号')
        return
      }
    },
    countdown() {
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.isClick = true
            this.count--
            this.sendCon = this.count + '秒后再次获取'
          } else {
            this.isClick = false
            clearInterval(this.timer)
            this.timer = null
            this.sendCon = '获取验证码'
          }
        }, 1000)
      }
    },
    //密码重置
    reset(formName) {
      _this.isDisabled = true
      this.$refs[formName].validate(valid => {
        this.resetpass.mobile = this.form.mobile
        if (valid) {
          _this.$http
            .post(process.env.VUE_APP_URL + 'updatePassword', this.resetpass)
            .then(data => {
              if (data.data.code == 200) {
                _this.$message.success('修改成功')
                _this.isDisabled = false
                this.resetpass.rpassword = ''
                this.resetpass.password = ''
              } else {
                _this.$message.error(data.data.msg)
                _this.isDisabled = false
              }
            })
            .catch(err => {
              _this.$message.error('网络错误，请重试')
              console.log(err)
              _this.isDisabled = false
            })
        } else {
          return false
        }
      })
    },
    //手机号修改
    resetMobile() {
      let mobile = this.mobileForm.mobile
      if (/^1[3456789]\d{9}$/.test(mobile)) {
        _this.$http
          .post(process.env.VUE_APP_URL + 'updateMobile', this.mobileForm)
          .then(data => {
            if (data.data.code == 200) {
              _this.$message.success('修改成功')
              _this.form.mobile = this.mobileForm.mobile
            } else {
              _this.$message.error(data.data.msg)
            }
          })
          .catch(err => {
            _this.$message.error('网络错误，请重试')
            console.log(err)
          })
      } else {
        this.$message.warning('请输入正确格式的手机号')
        return
      }
    },
    //推荐人状态
    agentstore() {
      if (_this.form.agent == '' || _this.form.agent == null) {
        _this.$http
          .post(process.env.VUE_APP_URL + 'isPay', { good_id: 1 })
          .then(data => {
            if (data.data.code == 201 && data.data.token != '') {
              this.readonly = true
            } else {
              this.readonly = false
            }
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        this.readonly = true
      }
    },
  },
}
</script>
<style lang="less" scoped>
.info {
  padding: 20px;
  .box_card {
    /deep/.el-tabs__header {
      max-width: 100%;
      margin: 0 0 25px;
    }
    /deep/.el-tabs__item {
      font-weight: bold;
      padding: 0px;
      font-size: 17px;
      font-family: Microsoft YaHei;
    }
    /deep/.el-tabs__nav.is-top {
      text-align: center;
    }
    /deep/.el-tabs__item.is-active {
      color: #960f23;
      border-bottom: 2px solid #960f23;
    }
    /deep/.el-tabs__active-bar {
      height: 0px;
    }
    /deep/.el-tabs__nav-wrap::after {
      display: none;
    }
    /deep/.el-tabs__nav-wrap {
      margin: 0px auto;
    }
    .el-select {
      width: 100%;
    }
    .el-form-item {
      margin-bottom: 10px;
    }
    /deep/.btn .el-form-item__content {
      margin-left: 0px !important;
      text-align: center;
      .el-button {
        margin-top: 10px;
        background: #960f23;
        border-radius: 4px;
        border: none;
        width: 145px;
      }
    }
    /deep/.el-form-item__label {
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
  }
  .phoneform {
    /deep/.el-form-item__content {
      margin-left: 100px !important;
    }
    /deep/.el-form-item__label {
      width: 100px !important;
    }
    .getcode {
      color: #960f23;
      border: 1px solid #960f23;
    }
  }
}
</style>
